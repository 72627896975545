import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import Map from "../components/map.js";
import "../css/kontakt.css";
import favicon from '../assets/favicon.ico';

const KontaktPage = () => {
  return (
    <div>
      <Helmet>
        <title>Pneuhaus Rogenmoser</title>
        <meta charset="utf-8" />
        <meta name="description" content="Willkommen bei Pneuhaus Rogenmoser in Auw. Ihr Reifenhändler in der Region Freiamt." />
        <meta name="keywords" content="Pneu,Pneuservice,Pneuverkauf,Pneuhandel,Reifenservice,Reifenhandel,Auw,Muri,Merenschwand,Sins,Freiamt,Aargau,Reifen," />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Melanie Rogenmoser"/>
        <meta name="copyright" content="Melanie Rogenmoser"/>
        <meta name="date" content="2022-08-09"/>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>

      <Navbar />

      <section className="presentation">
        <div className="kontakt">
          <div className="text">
            <h1>Kontakt</h1>
            <h2>Pneuhaus Rogenmoser GmbH</h2>
            <h3>Industriestrasse 19</h3>
            <h3>5644 Auw </h3>
            <br></br>
            <h3>Tel.  &nbsp;
            <a href="callto:079 445 14 27">079 445 14 27</a>
            <a href="callto:056 664 49 58">056 664 49 58</a>
            </h3>

            <br></br>
            <h3>Mail &nbsp;
            <a href="mailto:pneu.rogenmoser@bluewin.ch">pneu.rogenmoser@bluewin.ch</a>
            </h3>
            <br></br>
            <br></br>
            <br></br>
            <h2>Öffnungszeiten</h2>
            <h3>Mo-Fr 07:30 - 12:00 I 13.30 - 17.00</h3>
            <h3>Sa        07:30 - 12.00 I 13.30 - 16:00</h3>
            <p>an Feiertagen geschlossen</p>
          </div>
          <Map/>
        </div>
      </section>
      <Footer/>
    </div>
  );
};

export default KontaktPage;
